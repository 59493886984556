import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import HeaderImage from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Heading     from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer      from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper     from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import CallToAction
                   from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';

function AboutPage(props) {
  return (
    <>
      <HeaderImage>
        <Img fluid={props.data.headerImage.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <Heading subtitle={'Juwelier Deutsch'}>Über uns</Heading>
        <Spacer/>
        <TeamMembers layout={'list'}/>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default AboutPage;

export const query = graphql`
  query AboutPageQuery {
    headerImage: file(relativePath: {eq: "headers/about.png"}) {
      name
      childImageSharp {
        fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
import React                   from 'react';
import { Col, Container, Row } from 'react-grid-system';
import Img                     from 'gatsby-image';
import { graphql }             from 'gatsby';

import CallToAction      from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Display           from '@interness/theme-default/src/components/Display/Display';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Carousel          from '@interness/web-core/src/components/media/Carousel/Carousel';
import SEO               from '@interness/web-core/src/components/modules/SEO/SEO';
import ResponsiveIFrame  from '@interness/web-core/src/components/structure/iFrame/iFrame';

function IndexPage({data}) {
  return (
    <>
      <SEO title="Startseite"/>
      <Carousel>
        {data.headerImages.images.map((image) => (
          <Img key={image.file.localFile.name}
               fluid={image.file.localFile.childImageSharp.fluid}
               alt={image.file.title}/>
        ))}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading subtitle={'Juwelier Deutsch'}>Herzlich Willkommen</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Hallo und willkommen bei Juwelier Deutsch in Hannoversch Münden, mitten in der Fußgängerzone unserer schönen Fachwerkstadt.</p>
            <p>Auf traditionelle Weise trägt Juwelier Deutsch seit mehr als 40 Jahren in Hannoversch Münden dazu bei, Ihre Wünsche und Träume bei Uhren, Schmuck und exklusiven Trauringen und Eheringen zu erfüllen.</p>
            <p>Gerne begrüßen wir Sie in unserem neuen Geschäft in der Langestr. 70 und wünschen Ihnen ein wundervolles Einkauferlebnis.</p>
            <p>Wir laden Sie herzlich ein, bei Ihrem Besuch in unserem Geschäft Ihre Lieblingsstücke auszuwählen, anzuprobieren und zu begutachten.</p>
            <p>In unserem fein ausgewählten Sortiment finden Sie sowohl erlesenen als auch top aktuellen Schmuck, moderne und klassische Uhren, traumhafte Trauringe und Eheringe sowie extravagante Accessoires.</p>
            <p>Kompetente und persönliche Beratung rund um die Themen Schmuck, Uhren und bei Trauringen und Eheringen ist für das Team von Juwelier Deutsch selbstverständlich.</p>
            <p>Wir bieten Ihnen einen ausgezeichneten Service mit Pflege und Reparaturen von Ihren Lieblingstücken.</p>
            <p>Wir kaufen Ihr Altgold zu fairen und tagesaktuellen Preisen an.</p>
            <p>Bis bald in Hannoversch Münden. <br/>
              Ihr Team von Juwelier Deutsch.</p>
          </span>
        </section>
        <LiveAnnouncements/>
        <Spacer/>
        <section>
          <Heading tag={'h2'}>Unser Sortiment</Heading>
          <Display/>
          <Spacer/>
        </section>
        <section>
          <Heading tag={'h2'}>Video</Heading>
          <ResponsiveIFrame maxHeight={56.25}
                            src={`https://player.vimeo.com/video/836345628?h=ae5d1b0eb0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                            title={`VimeoVideo`}
                            type={'vimeo'}/>
        </section>
        <Spacer/>
        <section>
          <Heading>Unser Service für Sie</Heading>
          <p>Kompletter Service ist für unser Team selbstverständlich. Darum werden Schmuck und Uhren in unserer eigenen
            Werkstatt repariert, umgearbeitet oder angefertigt. So erfüllen wir Ihre Wünsche schnell, sicher und mit
            persönlichem Ansprechpartner.</p>
          <Container fluid>
            <Row>
              <Col md={6}>
                <h3>Armbanduhren</h3>
                <ul>
                  <li>mechanische Uhren / Handaufzug</li>
                  <li>Quarzarmbanduhren</li>
                  <li>Automatikuhren</li>
                  <li>Batteriewechsel</li>
                  <li>Komplette Revisionen</li>
                  <li>Taschenuhren</li>
                  <li>Glasersatz</li>
                  <li>Leder-, Metall-, Kunststoffuhrarmbänder</li>
                  <li>Bandanpassung</li>
                </ul>
              </Col>
              <Col md={6}>
                <h3>Goldschmiedereparaturen</h3>
                <ul>
                  <li>Goldschmiedereparaturen aller Art</li>
                  <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
                  <li>Ringgrößen ändern</li>
                  <li>Perlenketten neu fassen oder knoten</li>
                  <li>Trauringumarbeitung</li>
                  <li>Neuanfertigungen</li>
                  <li>Schmuck reinigen und aufarbeiten</li>
                  <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <h3>Neuwertermittlung</h3>
                <ul>
                  <li>Bewertung von Schmuckstücken</li>
                  <li>Aufarbeitung</li>
                  <li>Erfassung für Versicherungen, Erbschaften usw.</li>
                </ul>
              </Col>
              <Col md={6}>
                <h3>Altgoldankauf</h3>
                <ul>
                  <li>Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen an:</li>
                  <li>Zahngold, Goldnuggets, Altgold aus Schmuckstücken.</li>
                  <li>Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen.</li>
                  <li>Altgoldankauf ist Vertrauenssache</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "home"}) {
            name
            images: media {
                file {
                    title
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;